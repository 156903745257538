import {isInViewport} from "../Helpers/Scroll";
import {getDevice} from "../Helpers/Device";

const selectors = {
	parallax: '.js--parallax',
	sectionsSequence: '.sections-sequence',
	parallaxWrapperClassName: 'parallaxed',
};

class Parallax {

	constructor() {
		this.parallax = document.querySelectorAll(selectors.parallax);

		if (this.parallax.length === 0 || getDevice() !== 'desktop') {
			return;
		}

		this.onViewPortParallax();
	}

	objAnimation(el, valueY, valueOpacity) {
		const parseValue = parseInt(valueY, 10);
		el.style.transform = `translateY(${parseValue}px)`;
		el.style.opacity = valueOpacity;
	}

	easeAnim(id, from, to, currentPosition) {
		const translateToValue = 125;

		const duration = to - from - translateToValue;
		const durationOpacity = (to - from) / 3;
		const progress = currentPosition / duration;
		const progressForOpacity = currentPosition / durationOpacity;
		const currentEasingValue = easInOutQuart(progress);
		const yPosition = - currentEasingValue * translateToValue;
		const valueOpacity =  0.8 + easInOutQuart(progressForOpacity) * 0.2;

		this.objAnimation(id, yPosition, valueOpacity);

		function easInOutQuart(t) {
			return t < 0? 0: t <= 1 ? t : 1;
		}

	}

	onViewPortParallax() {
		document.querySelector(selectors.sectionsSequence).classList.add(selectors.parallaxWrapperClassName);
		const parallaxElement = this.parallax;
		let scrollPosition = 0;
		window.addEventListener("scroll", () => {
			scrollPosition = document.documentElement.scrollTop + window.innerHeight;

			parallaxElement.forEach((item) => {
				const sibling = item.nextElementSibling;
				const rect = sibling.getBoundingClientRect();

				const startPosition = rect.top + document.documentElement.scrollTop + window.innerHeight *0.25;
				const endPosition =  startPosition + window.innerHeight * .75 + item.offsetHeight;

				if (isInViewport(item, 0)) {
					this.easeAnim(item, startPosition, endPosition, scrollPosition - startPosition);
				}
			});

		});
	}

}

export default Parallax;


