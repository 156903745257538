/* helpers method to create/read/delete cookies.
* Usage:
* if you want create single cookie please use: createCookie('name', 'value');
* */

class Cookie {
	/**
	 * Create cookie.
	 * @param name
	 * @param value
	 * @param days
	 * @param path
	 * @param domain
	 * @param secure
	 */
	createCookie(name, value, days, path, domain, secure) {
		if (!navigator.cookieEnabled) {
			return;
		}
		const cookieName = encodeURIComponent(name);
		const cookieValue = encodeURIComponent(value);
		let cookie = `${cookieName} = ${cookieValue}`;

		if (typeof days === 'number') {
			const data = new Date();
			data.setTime(data.getTime() + (days * 24 * 60 * 60 * 1000));
			cookie += `; expires=${data.toGMTString()}`;
		}

		if (path) {
			cookie += `; path=${path}`;
		}
		if (domain) {
			cookie += `; domain=${domain}`;
		}
		if (secure) {
			cookie += '; secure';
		}


		document.cookie = cookie;
	}

	/**
	 * Read cookie.
	 * @param name
	 */
	readCookie(name) {
		if (document.cookie !== '') {
			const cookies = document.cookie.split(/; */);

			for (let i = 0; i < cookies.length; i++) {
				const cookieName = cookies[i].split('=')[0];
				const cookieVal = cookies[i].split('=')[1];
				if (cookieName === decodeURIComponent(name)) {
					return decodeURIComponent(cookieVal);
				}
			}
		}
		return false;
	}

	/**
	 * Remove cookie. Set param 'expires' to earlier than the current date.
	 * @param name
	 */
	removeCookie(name) {
		const cookieName = encodeURIComponent(name);
		document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
	}
}

export default Cookie;
