const selectors = {
	btnViewAll: '.js--view-all',
	meetAll: '.js--meet-all',
	meetIntro: '.js--meet-intro',
};

class MeetShowHide {

	constructor() {
		this.btn = document.querySelector(selectors.btnViewAll);
		this.meetAll = document.querySelector(selectors.meetAll);
		this.meetIntro = document.querySelector(selectors.meetIntro);

		if (!this.btn && !this.meetIntro && !this.meetAll) {
			return;
		}

		this.animateMeetContainer();
	}

	animateMeetContainer() {

		this.btn.addEventListener('click', () => {
			this.meetIntro.classList.add('hidden-meet');
			this.meetAll.classList.add('visible-meet');
		});

	}
}


export default MeetShowHide;
