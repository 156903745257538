import axios from 'axios';
import { paths } from '../Api/Paths';
import BaseClass from 'vendor/BaseClass';
import ENV from '../Config/Env';
import MESSAGES from '../Config/Messages';

const FOOTER_PROPS = {
    newsletterFormSelector: '.js--footer-newsletter-form',
    newsletterInputSelector: '.js--footer-newsletter-input',
    messageClassName: 'footer__newsletter__message',
    isValidClassName: 'is-valid',
    isInvalidClassName: 'is-invalid',
};

class Footer extends BaseClass {
    constructor() {
        super();
        this.initNewsletter();
    }

    initNewsletter () {
        this.newsletterForm = document.querySelector(FOOTER_PROPS.newsletterFormSelector);
        this.newsletterInput = document.querySelector(FOOTER_PROPS.newsletterInputSelector);

        if (this.newsletterForm && this.newsletterInput) {
            this.newsletterForm.addEventListener('submit', (event) => {
                event.preventDefault();
                this.setState({ newsletterSubmitted: true });
                this.sendForm();
            });

            this.newsletterInput.addEventListener('keyup', () => {
                if (this.state.newsletterSubmitted) {
                    this.checkNewsletterForm();
                }
            });
        }
    }

    sendForm() {
        if (this.checkNewsletterForm()) {
			grecaptcha.ready(() => {
				grecaptcha.execute(this.newsletterForm.dataset.recaptchaSiteKey, {action: 'submit'}).then((token) => {
					axios.post(paths.newsletter, {
						email: this.newsletterInput.value,
						'g-recaptcha-response' : token,
					}).then((response) => {
						this.newsletterForm.reset();
						this.setState({newsletterSubmitted: false});
						if (response.data && response.data.message) {
							this.addNewsletterMessage(response.data.message, FOOTER_PROPS.isValidClassName);
						}
					}).catch((error) => {
						if (error.response.data.message) {
							this.addNewsletterMessage(error.response.data.message, FOOTER_PROPS.isInvalidClassName);
						} else {
							this.addNewsletterMessage(MESSAGES.errors.default, FOOTER_PROPS.isInvalidClassName);
						}
					});
				})
			});
        }
    }

    checkNewsletterForm() {
        const isValid = this.isNewsletterEmailValid();

        if (isValid) {
            this.newsletterInput.classList.remove(FOOTER_PROPS.isInvalidClassName);
        } else {
            this.newsletterInput.classList.add(FOOTER_PROPS.isInvalidClassName);
        }

        return isValid;
    }

    isNewsletterEmailValid() {
        const value = this.newsletterInput.value;

        if (value.trim() === '') {
            this.addNewsletterMessage(MESSAGES.errors.required);
            return false;
        }

        if (!ENV.regex.email.test(value.toLowerCase())) {
            this.addNewsletterMessage(MESSAGES.errors.email);
            return false;
        }

        this.removeNewsletterMessage();
        return true
    }

    addNewsletterMessage(message = '', type = FOOTER_PROPS.isInvalidClassName) {
        this.removeNewsletterMessage();
        const messageElement = document.createElement('div');
        messageElement.className = `${FOOTER_PROPS.messageClassName} ${type}`;
        messageElement.innerHTML = message;

        this.newsletterForm.appendChild(messageElement);
    }

    removeNewsletterMessage() {
        const messageElement = this.newsletterForm.querySelector(`.${FOOTER_PROPS.messageClassName}`);

        if (messageElement) {
            messageElement.parentNode.removeChild(messageElement);
        }
    }

    initState() {
        this.state = {
            newsletterSubmitted: false
        };
    }
}

export default Footer;
