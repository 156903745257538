import panzoom from 'panzoom'
import {isMobileDevice} from '../Helpers/PointerInfo'
import {getDevice} from '../Helpers/Device'
import { scrollPage } from '../Helpers/Scroll';

const selectors = {
	mapSection: '.js--maps-section',
	mapContainer: '.js--map-container',
	mapParent: '.js--map-parent',
	mapPlaces: '.js--map-places',
	mapEssentials: '.js--map-king-essentials',
	mapGlobalScholars: '.js--map-global-scholars',
	btnEnableMapWrapper: '.js--enable-map-wrapper',
	btnDisableMapWrapper: '.js--disable-map-wrapper',
	btnEnableMap: '.js--enable-map',
	btnDisableMap: '.js--disable-map',
	marker: '.js--marker',
	getDetailsContainer: '.js--get-details-container',
	infoTouch: '.js--info-touch',
	infoClick: '.js--info-click',
};

const eventList = ["click", "touchend"];

class MapManipulation {

	constructor() {
		this.map = document.querySelector(selectors.mapPlaces);
		this.mapEssentials = document.querySelector(selectors.mapEssentials);
		this.mapGlobalScholars = document.querySelector(selectors.mapGlobalScholars);
		this.mapSection = document.querySelector(selectors.mapSection);
		this.mapContainer = document.querySelector(selectors.mapContainer);
		this.btnEnableMap = document.querySelector(selectors.btnEnableMap);
		this.btnDisableMap = document.querySelector(selectors.btnDisableMap);
		this.btnEnableMapWrapper = document.querySelector(selectors.btnEnableMapWrapper);
		this.btnDisableMapWrapper = document.querySelector(selectors.btnDisableMapWrapper);
		this.infoTouch = document.querySelector(selectors.infoTouch);
		this.infoClick = document.querySelector(selectors.infoClick);
		this.marker = document.querySelectorAll(selectors.marker);

		if (!this.map) {
			return;
		}

		this.runMap();
		this.loadAjaxDetails();
		this.toggleButtonsForTouchDevices();
		this.onOrientationChange();

	}

	runMap(){

		this.initMapEssentials();
		this.initMapGlobalScholars();

	}

	initMapEssentials(){
		const map = this.mapEssentials;

		if(!map){
			return;
		}

		const centerX = (document.querySelector(selectors.mapParent).offsetWidth / 2) - (map.offsetWidth / 2);
		const centerY = (document.querySelector(selectors.mapParent).offsetHeight / 2) - (map.offsetHeight / 2);
		const instance = panzoom(map, {
			beforeWheel: function (e) {
				// allow wheel-zoom only if altKey is down. Otherwise - ignore
				const shouldIgnore = !e.altKey;
				return shouldIgnore;
			},
			autocenter: false,
			zoomDoubleClickSpeed: 1,
			bounds: true,
			boundsPadding: 0.9,
			transformOrigin: {x: 0.5, y: 0.5},
			maxZoom: 2,
			minZoom: 0.5,
		});

		switch (getDevice()) {
			case 'desktop':
				instance.moveTo(centerX, centerY);
				instance.zoomAbs(
					window.innerWidth * 1.1,
					window.innerHeight / 2,
					1.2  // initial zoom
				);
				instance.getTransform().scale = 1.2;
				break;

			case 'mobile':
			case 'tablet':
				instance.moveTo(centerX, centerY);
				instance.zoomAbs(
					window.innerWidth / 4,
					window.innerHeight / 5,
					0.5  // initial zoom
				);
				instance.getTransform().scale = 0.5;
				map.classList.add('scale-map');
				break;
			default:
				return;

		}

	}


	initMapGlobalScholars() {
		const map = this.mapGlobalScholars;

		if(!map){
			return;
		}

		const centerX = (document.querySelector(selectors.mapParent).offsetWidth / 2) - (map.offsetWidth / 2);
		const centerY = (document.querySelector(selectors.mapParent).offsetHeight / 2) - (map.offsetHeight / 2);

		const instance = panzoom(map, {
			beforeWheel: function (e) {
				// allow wheel-zoom only if altKey is down. Otherwise - ignore
				const shouldIgnore = !e.altKey;
				return shouldIgnore;
			},
			autocenter: false,
			zoomDoubleClickSpeed: 1,
			bounds: true,
			boundsPadding: 0.8,
			transformOrigin: {x: 0.5, y: 0.5},
			maxZoom: 2,
			minZoom: 0.5,
		});

		switch (getDevice()) {
			case 'desktop':
				instance.moveTo(centerX, centerY);
				instance.zoomAbs(
					window.innerWidth / 1.5,
					window.innerHeight / 1.2,
					1.2  // initial zoom
				);
				instance.getTransform().scale = 1.2;
				break;

			case 'mobile':
			case 'tablet':
				instance.moveTo(centerX, centerY);
				instance.zoomAbs(
					window.innerWidth / 1.5,
					window.innerHeight / 5,
					0.5  // initial zoom
				);
				instance.getTransform().scale = 0.5;
				map.classList.add('scale-map');
				break;
			default:
				return;

		}

	}


	loadAjaxDetails(){

		/* get all markers on the map */
		const markers = this.marker;

		markers.forEach(item => {

			for(event of eventList) {
				item.addEventListener(event, function(evt) {

					evt.preventDefault();

					/* toggle active class */
					for (let i = 0; i < markers.length; i++) {
						markers[i].classList.remove('active');
					}
					item.classList.add('active');

					/* open request */
					const request = new XMLHttpRequest();
					request.open('GET', item.href, true);

					request.onload = function () {
						if (this.status >= 200 && this.status < 400) {

							const maps = document.querySelector(selectors.mapSection);
							const existOldSection = document.querySelector(selectors.getDetailsContainer);

							/* if exist old container - remove it */
							if(existOldSection) {
								existOldSection.parentNode.removeChild(existOldSection);
							}

							/* get html from URL */
							const parser = new DOMParser();
							const doc = parser.parseFromString(this.response, "text/html");
							const items = doc.querySelector(selectors.getDetailsContainer);

							maps.insertAdjacentElement('afterend', items);

							/* scroll to section */
							scrollPage(items, 500, 'linear', -80);

						} else {
							// Response error
						}
					};
					request.onerror = function () {
						// Connection error
					};
					request.send();
					return false;

				});
			}
		});

	}


	toggleButtonsForTouchDevices() {
		if (isMobileDevice()) {

			this.infoClick.classList.add('hidden');
			this.infoTouch.classList.remove('hidden');
			this.showButtonEnable();

			this.btnEnableMap.addEventListener('click', (evt) => {
				evt.preventDefault();
				this.showButtonDisable();
			});

			this.btnDisableMap.addEventListener('click', (evt) => {
				evt.preventDefault();
				this.showButtonEnable();
			});
		} else {
			this.hideAllButtons();
			this.infoTouch.classList.add('hidden');
			this.infoClick.classList.remove('hidden');
		}
	}

	hideAllButtons() {
		this.btnEnableMapWrapper.classList.remove('active');
		this.btnDisableMapWrapper.classList.remove('active');
	}

	showButtonEnable() {
		this.btnEnableMapWrapper.classList.add('active');
		this.btnDisableMapWrapper.classList.remove('active');
	}

	showButtonDisable() {
		this.btnEnableMapWrapper.classList.remove('active');
		this.btnDisableMapWrapper.classList.add('active');
	}

	onOrientationChange() {
		window.addEventListener('orientationchange', () => {
			window.location.reload();
		})
	}
}

export default MapManipulation;
