import BaseClass from 'vendor/BaseClass';
import { getScrollPosition } from '../Helpers/Scroll';
import { getDevice } from '../Helpers/Device';

const STICKY_NAV_PROPS = {
    wrapperSelector: '.js--sticky-nav',
    placeholderSelector: '.js--sticky-nav-placeholder',
    menuSelector: '.js--sticky-nav-menu',
    stickyOffset: 75,
};

class StickyNavItem extends BaseClass {
    constructor (wrapper) {
        super();
        this.wrapper = wrapper;
        
        if (this.wrapper) {
            this.init();
        }
    }

    /**
     * Init component
     */
    init () {
        this.menu = this.wrapper.querySelector(STICKY_NAV_PROPS.menuSelector);
        this.placeholder = this.wrapper.querySelector(STICKY_NAV_PROPS.placeholderSelector);

        if (this.menu && this.placeholder) {
            this.initState();
            this.events();
        }
    }

    /**
     * Component actions
     */
    events() {
        this.checkPosition();
        window.addEventListener('scroll', this.checkPosition.bind(this));
        window.addEventListener('resize', this.checkPosition.bind(this));
    }

    /**
     * Check page scroll and menu position
     */
    checkPosition() {
        const scrollPosition = getScrollPosition();
        const placeholderPosition = this.placeholder.offsetPosition();

        // if breakpoint is `desktop` and window scroll position + offset more than menu postion -> set menu fixed
        // else -> unset menu fixed
        if (getDevice() === 'desktop' && scrollPosition.top + STICKY_NAV_PROPS.stickyOffset >= placeholderPosition.top) {
            this.setState({
                isSticky: true,
                position: {
                    ...this.state.position,
                    left: placeholderPosition.left,
                }
            });
        } else {
            this.setState({
                isSticky: false,
            });
        }

        this.setMenu();
    }

    /**
     * Set/unset stocky menu
     */
    setMenu() {
        if (this.state.isSticky) {
            this.menu.classList.add('is-sticky');
            this.menu.style.top = `${this.state.position.top}px`;
            this.menu.style.left = `${this.state.position.left}px`;
            this.placeholder.style.height = `${this.menu.clientHeight}px`;
        } else {
            this.menu.classList.remove('is-sticky');
            this.menu.style.top = 'auto';
            this.menu.style.left = 'auto';
            this.placeholder.style.height = 0;
        }
    } 

    /**
     * Init state
     */
    initState() {
        this.state = {
            isSticky: false,
            position: {
                top: STICKY_NAV_PROPS.stickyOffset,
                left: 0,
            },
        };
    }
}

class StickyNav {
    constructor () {
        const sections = document.querySelectorAll(STICKY_NAV_PROPS.wrapperSelector);

        if (sections.length > 0) {
            for (const section of sections) {
                new StickyNavItem(section);
            }
        }
    }
}

export default StickyNav;
