import BaseClass from 'vendor/BaseClass';
import { getDevice } from '../Helpers/Device';

const SIDEBAR_MENU_PROPS = {
    wrapperSelector: '.js--sidebar-menu',
    buttonSelector: '.js--sidebar-menu-button',
    bodySelector: '.js--sidebar-menu-body',
    listSelector: '.js--sidebar-menu-list',
    menuAttr: 'data-menu-id',
    activeClassName: 'is-active',
    openedClassName: 'is-opened',
};

class SidebarMenuItem extends BaseClass {
    constructor (wrapper) {
        super();
        this.wrapper = wrapper;
        
        if (this.wrapper) {
            this.init();
        }
    }

    /**
     * Init component
     */
    init () {
        this.buttons = this.wrapper.querySelectorAll(SIDEBAR_MENU_PROPS.buttonSelector);
        this.body = this.wrapper.querySelector(SIDEBAR_MENU_PROPS.bodySelector);
        this.list = this.wrapper.querySelectorAll(SIDEBAR_MENU_PROPS.listSelector);

        if (this.buttons.length) {
            this.initState();
            this.events();
            this.setMenu();
        }
    }

    /**
     * Component actions
     */
    events() {
        this.buttons.onEvent('click', (event) => this.onButtonClick(event));
        document.addEventListener('click', (event) => this.onDocumentClick(event));
        window.addEventListener('resize', this.onWindowResize.bind(this));
    }

    /**
     * On button click
     * @param {MouseEvent} event 
     */
    onButtonClick(event) {
        event.preventDefault();
        const menuId = this.getElementMenuIdAttr(event.target);

        this.setState({
            activeMenuId: menuId,
        });

        // Open/close dropdown if mobile or tablet
        if (this.state.device !== 'desktop') {
            this.setState({
                openedMenuId: this.state.openedMenuId === menuId ? null : menuId,
            });
        }

        // Update menu view
        this.setMenu();
    }

    /**
     * On document click
     * @param {MouseEvent} event 
     */
    onDocumentClick(event) {
        const isClickInside = this.wrapper.contains(event.target);
          
        // Close dropdown if click outside
        if (!isClickInside && this.state.openedMenuId) {
            this.setState({ openedMenuId: null });
            this.setMenu();
        }
    }

    /**
     * On window resize
     */
    onWindowResize() {
        const currentDevice = getDevice();

        if (this.state.device !== currentDevice) {
            this.setState({
                device: currentDevice,
            });
        }

        // Update menu view
        this.setMenu();
    }

    /**
     * Set tabs view
     */
    setMenu() {
        this.resetAll();

        const activeElements = this.getElementsByMenuId(this.state.activeMenuId);
        const openedElements = this.getElementsByMenuId(this.state.openedMenuId);

        if (activeElements) {
            activeElements.buttons.addClassToAll(SIDEBAR_MENU_PROPS.activeClassName);
            activeElements.list.classList.add(SIDEBAR_MENU_PROPS.activeClassName);
        }

        if (openedElements) {
            openedElements.buttons.addClassToAll(SIDEBAR_MENU_PROPS.openedClassName);
            openedElements.list.classList.add(SIDEBAR_MENU_PROPS.openedClassName);
        }

        if (activeElements && getDevice() === 'desktop') {
            this.body.style.height = `${activeElements.list.scrollHeight}px`;
        } else {
            this.body.style.height = 'auto';
        }
    }

    /**
     * Reset all states
     */
    resetAll() {
        this.buttons.removeClassFromAll(SIDEBAR_MENU_PROPS.activeClassName);
        this.buttons.removeClassFromAll(SIDEBAR_MENU_PROPS.openedClassName);
        this.list.removeClassFromAll(SIDEBAR_MENU_PROPS.activeClassName);
        this.list.removeClassFromAll(SIDEBAR_MENU_PROPS.openedClassName);
        this.body.style.height = 'auto';
    }

    /**
     * Get elements by menu id
     * @param {String} menuId 
     */
    getElementsByMenuId(menuId) {
        if (!menuId) {
            return false;
        }

        const attr = `[${SIDEBAR_MENU_PROPS.menuAttr}="${menuId}"]`;

        return {
            buttons: this.wrapper.querySelectorAll(`${SIDEBAR_MENU_PROPS.buttonSelector}${attr}`),
            list: this.wrapper.querySelector(`${SIDEBAR_MENU_PROPS.listSelector}${attr}`),
        }
    }

    /**
     * Get menu id from element element
     * @param {HTMLElement} element 
     */
    getElementMenuIdAttr(element) {
        return element && element.hasAttribute(SIDEBAR_MENU_PROPS.menuAttr)
            ? element.getAttribute(SIDEBAR_MENU_PROPS.menuAttr)
            : '';
    }


    /**
     * Init state
     */
    initState() {
        const inititalDevice = getDevice();

        this.state = {
            activeMenuId: this.getElementMenuIdAttr(this.wrapper.querySelector(`${SIDEBAR_MENU_PROPS.buttonSelector}.${SIDEBAR_MENU_PROPS.activeClassName}`)),
            openedMenuId: null,
            prevDevice: inititalDevice,
            device: inititalDevice,
        };
    }
}

class SidebarMenu {
    constructor () {
        const sections = document.querySelectorAll(SIDEBAR_MENU_PROPS.wrapperSelector);

        if (sections.length > 0) {
            for (const section of sections) {
                new SidebarMenuItem(section);
            }
        }
    }
}

export default SidebarMenu;