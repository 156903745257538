export const TilesGrid = () => { 

    function setTilesGridsHeight() {
        let minTileHeight = 0;
        if( document.getElementsByClassName('js--tile-grid-item').length > 0 ){
            document.querySelectorAll('.js--tile-grid-item .single-tile__text').forEach((item) => {                
                if( item.offsetHeight>minTileHeight ){
                    minTileHeight = item.offsetHeight;
                }
            });          
            document.querySelectorAll('.js--tile-grid-item .single-tile__text').forEach((item) => {
                item.style.height = minTileHeight+'px';
            });
        }
    };

      setTilesGridsHeight();     
      let resizeTimeout; 
      window.addEventListener('resize', () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(
            setTilesGridsHeight(),
            100
        )       
      });
      
};