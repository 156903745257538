import Cookie from '../Helpers/Cookies';

const cookie = new Cookie();

const ViewTheFilmPopup = () => {
  
    const mainPopup = document.querySelector('.main-popup');
    const videoFilmPopup = document.querySelector('.view-the-film-popup');
    const openButton = document.querySelector('.js--view-the-film-button');
    const openButtonBtn = document.querySelector('.js--view-the-film-button-main');
    const playButton = document.querySelector('.js--play-video-button');
    const closeButton = document.querySelector('.js--close-video-button');
    const closePopupButton = document.querySelector('.js--close-popup-button');
    const triangleButtons = document.querySelectorAll('.js--play-new-video-button');
    const searchString = window.location.search;
    const searchParams = new URLSearchParams(searchString);
    const videoParam = searchParams.get('video');    
    let windowHeight = window.innerHeight;
    let windowWidth = window.innerWidth;
    let playerHeight = 0.8*windowHeight;
    let playerWidth = playerHeight*1.7777;
    let youtubeMainVideoId = videoFilmPopup.dataset.youtubeId;
    if( windowWidth<=1024 ){
        playerWidth = windowWidth;
        playerHeight = playerWidth*0.5625;
    }

    if( !mainPopup || !videoFilmPopup ){        
        return;
    }

    window.addEventListener('resize', () => {
        windowHeight = window.innerHeight;
        windowWidth = window.innerWidth;
        if( windowWidth<=1024 ){
            playerWidth = windowWidth-32;
            playerHeight = playerWidth*0.5625;
        }else{
            playerHeight = 0.8*windowHeight;
            playerWidth = playerHeight*1.7777;
        }
        player.setSize(playerWidth, playerHeight);
    });

    let tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.
    let player;
    window.onYouTubeIframeAPIReady = function() {
        
        player = new YT.Player('video-film', {
            height: '315',
            width: '560',
            videoId: youtubeMainVideoId,
            playerVars: {
                'playsinline': 1
            },
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
        });
    }

    const playVideo = () => {        
        document.body.classList.add('video-film-playing');        
        player.playVideo();
    }

    const closeVideo = () => {
        document.body.classList.remove('video-film-playing');
        document.body.classList.remove('video-film-popup-opened');
        pauseVideo();
    }

    let done = false;
    const onPlayerReady = (event) => {
        player.setSize(playerWidth, playerHeight);
        if( triangleButtons ){
            for (var i = 0; i < triangleButtons.length; i++) {
                var self = triangleButtons[i];
    
                self.addEventListener('click', function (event) {  
                    // prevent browser's default action
                    event.preventDefault();
                    let youtubeVideoId = this.dataset.youtubeId;
                    openVideoPopup(youtubeVideoId);
                }, false);
            }               
        }
        if( openButton ){
            openButton.addEventListener('click', function(){
                openVideoPopup(youtubeMainVideoId)
            }, false);
        }
        if( openButtonBtn ){
            openButtonBtn.addEventListener('click', function(){
                openVideoPopup(youtubeMainVideoId)
            }, false);
        }
    }
    const onPlayerStateChange = (event) => {
        /*if (event.data == YT.PlayerState.PLAYING && !done) {
            setTimeout(closeVideo, 6000);
            done = true;
        }*/
    }

    const openVideoPopup = (youtubeVideoId) => {
        closeMainPopup();
        player.loadVideoById(youtubeVideoId);
        document.body.classList.add('video-film-popup-opened');
        playVideo();        
    }

    const openMainPopup = () => {
        if (!(cookie.readCookie('main_popup_seen') === 'seen')) {			
            document.body.classList.add('main-popup-opened');
		}
        cookie.createCookie('main_popup_seen', 'seen', 1, __jsVars.cookie.path, __jsVars.cookie.domain, __jsVars.cookie.secure);                
    }
    const openPopupAndPlayVideo = () => {

    }
    const closeMainPopup = () => {
        document.body.classList.remove('main-popup-opened');
    }
    
    const stopVideo = () => {
        player.stopVideo();
    }

    const pauseVideo = () => {
        player.pauseVideo();
    }

    if( playButton ){
        playButton.addEventListener('click', playVideo);
    }
    if( closeButton ){
        closeButton.addEventListener('click', closeVideo);
    }
    if( closePopupButton ){
        closePopupButton.addEventListener('click', closeMainPopup);
    }

    if (videoParam === '1') {
        openVideoPopup();
    }

    window.addEventListener('click', event => {
        if (event.target) {
            if (event.target === videoFilmPopup) {
                closeVideo();
            }
            if (event.target === mainPopup) {
                closeMainPopup();
            }
        }
    });

    openMainPopup();

}

export default ViewTheFilmPopup;
  