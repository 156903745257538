import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';
import BaseClass from 'vendor/BaseClass';
import { isUsingMousePointer } from '../Helpers/PointerInfo';
import { scrollPage } from '../Helpers/Scroll';
import { getDevice } from '../Helpers/Device';

const FULL_SCREEN_SLIDER_PROPS = {
    wrapperSelector: '.js--full-screen-slider',
    swiperSelector: '.js--full-screen-swiper',
    textSelector: '.js--full-screen-text',
    buttonSelector: '.js--go-to-slide',
    swiperDescSelector: '.js--full-screen-swiper-description',
    buttonScrollToNextSectionSelector: '.js--button-scroll-to-next-section',
    textHoverClassName: 'is-hovered',
    textActiveClassName: 'is-active',
    buttonGoToSlideAttr: 'data-go-to-slide',
    slidersSpeed: 500,
};

class FullScreenSliderItem extends BaseClass {
    constructor (section) {
        super();
        this.section = section;
        this.init();
    }

    /**
     * Component init
     */
    init() {
        this.text = this.section.querySelector(FULL_SCREEN_SLIDER_PROPS.textSelector);
        this.buttons = this.text.querySelectorAll(FULL_SCREEN_SLIDER_PROPS.buttonSelector);
        this.description = this.section.querySelector(FULL_SCREEN_SLIDER_PROPS.swiperDescSelector);
        this.buttonScrollToNextSection = this.section.querySelector(FULL_SCREEN_SLIDER_PROPS.buttonScrollToNextSectionSelector);
        this.swiper = null;
        this.swiperDescription = null;

        this.initState();
        this.events();
        this.initSliders();
        //this.setHeight();
    }

    /**
     * Component actions
     */
    events() {
        if (this.buttonScrollToNextSection) {
            this.buttonScrollToNextSection.addEventListener('click', () => {
                scrollPage(this.section.clientHeight, 500);
            });
        }
        
        if (this.buttons.length > 0) {
            this.buttons.onEvent('mouseover', (event) => {
                event.preventDefault();
                const activeSlide = this.getButtonIndex(event.target);

                if (this.state.activeSlide === activeSlide) {
                    //this.setState({ activeSlide: 0 });

                    //if (getDevice() !== 'desktop') {
                        //this.setState({ hoveredIndex: 0 });
                        //this.setHover();
                    //}
                } else {
                    this.setState({ activeSlide: this.getButtonIndex(event.target) });
                }
                this.setActive();
            }).onEvent('mouseout', () => {
                //this.setState({ hoveredIndex: 0 });
                //this.setHover();
            });/*.onEvent('mouseover', (event) => {
                if (isUsingMousePointer()) {
                    this.setState({ hoveredIndex: this.getButtonIndex(event.target) });
                    this.setHover();
                }
            }).onEvent('mouseout', () => {
                this.setState({ hoveredIndex: 0 });
                this.setHover();
            });*/
        }
    }

    /**
     * Get index attribute from event target
     * @param {HTMLElement} target
     */
    getButtonIndex(target) {
        if (target && target.hasAttribute(FULL_SCREEN_SLIDER_PROPS.buttonGoToSlideAttr)) {
            return parseInt(target.getAttribute(FULL_SCREEN_SLIDER_PROPS.buttonGoToSlideAttr));
        }

        return 0;
    }

    /**
     * Set component on buttons hover
     */
    setHover() {
        if (!this.text) {
            return false;
        }

        // if hoveredIndex more than 0 -> set hover state for text
        // else -> unset hover state for text
        if (this.state.hoveredIndex > 0) {
            this.text.classList.add(FULL_SCREEN_SLIDER_PROPS.textHoverClassName);
        } else {
            this.text.classList.remove(FULL_SCREEN_SLIDER_PROPS.textHoverClassName);
        }
    }

    /**
     * Set component on buttons click
     */
    setActive() {
        // Remove active from all buttons
        this.buttons.removeClassFromAll(FULL_SCREEN_SLIDER_PROPS.textActiveClassName);

        // if active slide more than 0 -> set active state for text, set active button and show description
        // else -> unset active state from text and hide description

        if (this.state.activeSlide > 0) {
            this.text.classList.add(FULL_SCREEN_SLIDER_PROPS.textActiveClassName);
            this.buttons[this.state.activeSlide - 1].classList.add(FULL_SCREEN_SLIDER_PROPS.textActiveClassName);
            this.description.classList.add(FULL_SCREEN_SLIDER_PROPS.textActiveClassName);
            this.buttonScrollToNextSection.classList.add(FULL_SCREEN_SLIDER_PROPS.textActiveClassName);            

        } else {
            this.text.classList.remove(FULL_SCREEN_SLIDER_PROPS.textActiveClassName);
            this.description.classList.remove(FULL_SCREEN_SLIDER_PROPS.textActiveClassName);
			this.buttonScrollToNextSection.classList.remove(FULL_SCREEN_SLIDER_PROPS.textActiveClassName);
        }

        // set sliders to active slide state        
        this.swiperDescription.slideTo(this.state.activeSlide - 1, FULL_SCREEN_SLIDER_PROPS.slidersSpeed);
        //this.swiper.slideTo(this.state.activeSlide, FULL_SCREEN_SLIDER_PROPS.slidersSpeed);
    }

    /**
     * Init all sliders
     */
    initSliders() {
        const swiperElement = this.section.querySelector(FULL_SCREEN_SLIDER_PROPS.swiperSelector);

        // Init background slider
        if (swiperElement) {
            this.swiper = new Swiper(swiperElement, {
                modules: [Navigation, Pagination, EffectFade],
                effect: 'fade',
                slidesPerView: 1,
                cssMode: true,
                fadeEffect: { crossFade: true },
                allowTouchMove: false,
                speed: FULL_SCREEN_SLIDER_PROPS.slidersSpeed,
            });
        }

        // Init description slider
        if (this.description) {
            this.swiperDescription = new Swiper(this.description, {
                modules: [Navigation, Pagination, EffectFade],
                effect: 'fade',
                slidesPerView: 1,
                cssMode: false,
                fadeEffect: { crossFade: true },
                allowTouchMove: false,
                speed: FULL_SCREEN_SLIDER_PROPS.slidersSpeed,
            });
        }
    }

    /**
     * Init component state
     */
    initState() {
        this.state = {
            hoveredIndex: 0,
            activeSlide: 0,
        };
    }

    setHeight() {
    	let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
		document.querySelector(FULL_SCREEN_SLIDER_PROPS.wrapperSelector).style.height = `${vh}px`;

		window.addEventListener('resize', () => {
			const newHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
			if (Math.abs(vh - newHeight) > 160) {
				vh = newHeight;
				document.querySelector(FULL_SCREEN_SLIDER_PROPS.wrapperSelector).style.height = `${vh}px`;
			}
		});
	}

}

class FullScreenSlider {
    constructor () {
        const sections = document.querySelectorAll(FULL_SCREEN_SLIDER_PROPS.wrapperSelector);

        if (sections.length > 0) {
            for (const section of sections) {
                new FullScreenSliderItem(section);
            }
        }
    }
}

export default FullScreenSlider;
