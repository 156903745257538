import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';
import ENV from '../Config/Env';

export const FullScreenSliderWithText = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
    if( document.getElementsByClassName('js--hovered-box-item').length > 0 ){
        new Swiper('.js--full-screen-slider-with-text-swiper', {
            modules: [Navigation, Pagination, EffectFade],
            slidesPerView: 1,
            loop: true,
            effect: 'fade',
            autoplay: {
                delay: 4000,
            },
            speed: 1000,
        });
    }

    if( document.getElementsByClassName('js--video-background-hero').length > 0 ){
        let video = document.getElementsByClassName('js--video-background-hero')[0];
        let poster = document.getElementsByClassName('js--video-background-hero-poster')[0];
        let desktopVideoURL = video.dataset.desktopVideoSrc;
        let mobileVideoURL = video.dataset.mobileVideoSrc;
        let mobileRetinaVideoURL = video.dataset.mobileRetinaVideoSrc;
        let pixelRatio = window.devicePixelRatio;        
        if( windowWidth>768 ){
            video.setAttribute('src', desktopVideoURL);
        }else{
            if( pixelRatio>1.5 ){
                video.setAttribute('src', mobileRetinaVideoURL);
            }else{
                video.setAttribute('src', mobileVideoURL);
            }
        }
        video.addEventListener("loadedmetadata", (event) => {
            poster.classList.add('poster-hidden');
            video.classList.remove('video-hidden');            
            video.play();
        });
    }
};