/**
 * example usage:
 *
 * <div class="js--items">
 *        <div class="js--single-item">item</div>
 *        <div class="js--single-item">item</div>
 *        <div class="js--single-item">item</div>
 *        <div class="js--load-more-container"><a href="#" class="js--load-more">Load More</a></div>
 * </div>
 */

const loadMore = () => {

	const btnLoad = document.querySelectorAll('.js--load-more');

	if (!btnLoad) {
		return;
	}

	for (let i = 0; i < btnLoad.length; i++) {
		addEventListenerToLoadMoreBtn(btnLoad[i]);
	}

	function addEventListenerToLoadMoreBtn(btn) {

		btn.addEventListener('click', (evt) => {
			evt.preventDefault();
			const request = new XMLHttpRequest();
			request.open('GET', btn.href, true);
			const parser = new DOMParser();
			const itemsContainer = btn.closest('.js--items');
			const btnLoadContainer = btn.closest('.js--load-more-container');

			/* create loading text */
			const loadingText = document.createElement("div");
			loadingText.classList.add('loading');
			loadingText.innerHTML = "<p>Loading...</p>";

			/* insert loading text to DOM */
			itemsContainer.insertAdjacentElement('beforeend', loadingText);
			btnLoadContainer.remove();

			request.onload = function () {
				const doc = parser.parseFromString(this.response, "text/html");
				const items = doc.querySelectorAll('.js--single-item');
				const existLoadMore = doc.querySelector('.js--load-more-container');

				if (this.status >= 200 && this.status < 400) {
					loadingText.remove();

					/* ajax insert articles */
					for (let i = 0; i < items.length; i++) {
						itemsContainer.insertAdjacentElement('beforeend', items[i]);
					}

					if (existLoadMore) {
						itemsContainer.insertAdjacentElement('beforeend', existLoadMore);

						addEventListenerToLoadMoreBtn(existLoadMore.querySelector('.js--load-more'));
						new performanceLoadService();
					} else {
						/* remove button load more if not exist */
						btnLoadContainer.remove();
						new performanceLoadService();
					}

				} else {
					// Response error
				}
			};
			request.onerror = function () {
				// Connection error
			};
			request.send();

			return false;
		});
	}

};

export default loadMore;
