import axios from "axios/index";
import ENV from '../Config/Env';
import MESSAGES from '../Config/Messages';
import {paths} from "../Api/Paths";

const selectors = {
	contactForm: '.js--contact-form',
	containerMessage: '.js--form-message',
	firstName: '.js--input-first-name',
	lastName: '.js--input-last-name',
	email: '.js--input-email',
	message: '.js--input-message',
	fields: '.js--input-field',

};

const classNames = {
	messageClassName: 'message-info',
	isValidClassName: 'is-valid',
	isInvalidClassName: 'is-invalid',
};

class ContactForm {

	constructor() {
		this.contactForm = document.querySelector(selectors.contactForm);
		this.containerMessage = document.querySelector(selectors.containerMessage);
		this.firstName = document.querySelector(selectors.firstName);
		this.lastName = document.querySelector(selectors.lastName);
		this.email = document.querySelector(selectors.email);
		this.message = document.querySelector(selectors.message);
		this.fields = document.querySelectorAll(selectors.fields);

		if (!this.contactForm) {
			return;
		}

		this.formSubmit();
	}


	formSubmit() {

		this.contactForm.addEventListener('submit', (event) => {
			event.preventDefault();

			grecaptcha.ready(() => {
				grecaptcha.execute(event.target.dataset.recaptchaSiteKey, {action: 'submit'}).then( (token) => {

					const form = event.target;
					const values = Object.values(form).reduce((obj, field) => {
						obj[field.name] = field.value;
						return obj
					}, {});

					const data = JSON.stringify({
						...values,
						'g-recaptcha-response' : token,
						norobot: 'form',
					});

					axios.post(paths.form, data, {
						method: 'POST',
						headers: {
							'content-type': "application/json; charset=utf-8"
						}
					}).then((response) => {
						this.removeMessage();
						this.addMessage(MESSAGES.errors.success, classNames.isValidClassName);
						this.clearInputs();
					}).catch((error) => {
						this.removeMessage();
						this.removeClassFromInputs();

						for (const key in error.response.data.data.params) {
							this.addMessage(error.response.data.data.params[key], classNames.isInvalidClassName);
							document.querySelector(`[name=${key}]`).classList.add(classNames.isInvalidClassName);
						}
					})

				});
			});


		});
	}


	clearInputs() {
		for (let i = 0; i < this.fields.length; i++) {
			this.fields[i].value = "";
		}
	}

	removeClassFromInputs(){
		for (let i = 0; i < this.fields.length; i++) {
			this.fields[i].classList.remove(classNames.isInvalidClassName);
		}
	}


	addMessage(message = '', type = classNames.isInvalidClassName) {
		const messageElement = document.createElement('p');
		messageElement.className = `${classNames.messageClassName} ${type}`;
		messageElement.innerHTML = message;
		this.containerMessage.appendChild(messageElement);
	}

	removeMessage() {
		const messageElement = this.containerMessage.querySelectorAll(`.${classNames.messageClassName}`);

		if (messageElement) {
			for (let i = 0; i < messageElement.length; i++) {
				messageElement[i].parentNode.removeChild(messageElement[i]);
			}
		}
	}


}

export default ContactForm;
