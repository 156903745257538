import BaseClass from 'vendor/BaseClass';
import Accordion from 'vendor/Accordion/Accordion';
import { getScrollPosition, scrollPage } from '../Helpers/Scroll';
import { isMobileDevice } from '../Helpers/PointerInfo';
import { getDevice, isIE } from '../Helpers/Device';
import ENV from '../Config/Env';
import { bodyOverflow, getScrollBarWidth } from '../Helpers/Body';

const WHEEL_SLIDER_PROPS = {
    sectionSelector: '.js--wheel-slider',
    sectionPlaceholderSelector: '.js--wheel-slider-placeholder',
    accordionSelector: '.js--wheel-slider-accordion',
    imagesItemSelector: '.js--wheel-slider-images-item',
    buttonScrollUpSelector: '.js--wheel-slider-scroll-up',
    buttonScrollDownSelector: '.js--wheel-slider-scroll-down',
}

class WheelSlider extends BaseClass {
    constructor() {
        super();
        this.sectionWrapper = document.querySelector(WHEEL_SLIDER_PROPS.sectionSelector);
        this.sectionWrapperPlaceholder = document.querySelector(WHEEL_SLIDER_PROPS.sectionPlaceholderSelector);

        if (this.sectionWrapper) {
            this.images = this.sectionWrapper.querySelectorAll(WHEEL_SLIDER_PROPS.imagesItemSelector);
            this.buttonScrollDown = this.sectionWrapper.querySelector(WHEEL_SLIDER_PROPS.buttonScrollDownSelector);
            this.buttonScrollUp = this.sectionWrapper.querySelector(WHEEL_SLIDER_PROPS.buttonScrollUpSelector);
            this.accordion = new Accordion(WHEEL_SLIDER_PROPS.accordionSelector, {
                initialCollection: [0],
            });
            this.wheelEvent = this.getWheelEvent();

            this.initState();
            this.events();
        }
    }

    events() {
        this.onCheckPosition();
        window.addEventListener('resize', this.onWindowResize.bind(this));
        document.addEventListener('scroll', this.onCheckPosition.bind(this));
        document.addEventListener(this.wheelEvent, (event) => this.onMouseWheelCheckPosition(event));
        this.onChangeSlides();

        this.accordion.on('clickUpdate', (data) => {
            this.setState({ currentSlidePosition: data.state.activeCollection[0] });
            this.setSectionActiveSlide();
        });

        if (this.buttonScrollDown) {
            this.buttonScrollDown.addEventListener('click', this.onButtonScrollDownClick.bind(this));
        }

		if (this.buttonScrollUp) {
			this.buttonScrollUp.addEventListener('click', this.onButtonScrollUpClick.bind(this));
		}
    }

    /**
     * Check position on window scroll event
     */
    onCheckPosition() {
        if (this.isMechanismEnabled()) {
            const scrollPosition = getScrollPosition();
            const scrollDirection = this.getScrollDirection();

            this.preventWindowScroll();

            if (
                (scrollDirection === 'down' && this.state.currentSlidePosition === 0 && scrollPosition.top >= this.state.wrapperPosition.top)
                || (scrollDirection === 'up' && this.state.currentSlidePosition === this.state.countSlides && scrollPosition.top <= this.state.wrapperPosition.top)
            ){
                this.setPreventScroll(true);
            }
        } else {
            this.setPreventScroll(false);
        }
    }

    /**
     * Check position on mouse whell event
     * @param {WheelEvent} event
     */
    onMouseWheelCheckPosition(event) {
        if (!this.isMechanismEnabled()) {
            return false;
        }

        this.preventWindowScroll();
        const scrollPosition = getScrollPosition();

        if (
            (event.deltaY > 0 && this.state.currentSlidePosition === 0 && scrollPosition.top >= this.state.wrapperPosition.top)
            || (event.deltaY < 0 && this.state.currentSlidePosition === this.state.countSlides && scrollPosition.top <= this.state.wrapperPosition.top)
        ) {
            this.setPreventScroll(true);
        }
    }

    /**
     * Change slides on mouse whell
     */
    onChangeSlides() {
        const wheelEventHandler = (event) => {
            if (!this.isMechanismEnabled() || !this.state.isEnableMouseWheel) {
                return false;
            }

            this.sectionWrapper.removeEventListener(this.wheelEvent, wheelEventHandler);

            if (
                (event.deltaY > 0 && this.state.currentSlidePosition === this.state.countSlides)
                || (event.deltaY < 0 && this.state.currentSlidePosition === 0)
            ) {
                this.setPreventScroll(false);
            }

            if (event.deltaY < 0) {
                if (this.state.currentSlidePosition - 1 > 0) {
                    this.setState({ currentSlidePosition: this.state.currentSlidePosition - 1 });
                } else {
                    this.setState({ currentSlidePosition: 0 });
                }
            } else {
                if (this.state.currentSlidePosition + 1 < this.state.countSlides) {
                    this.setState({ currentSlidePosition: this.state.currentSlidePosition + 1 });
                } else {
                    this.setState({ currentSlidePosition: this.state.countSlides });
                }
            }

            this.setSectionActiveSlide();

            setTimeout(() => {
                this.sectionWrapper.addEventListener(this.wheelEvent, wheelEventHandler);
            }, 1200);
        }

        this.sectionWrapper.addEventListener(this.wheelEvent, wheelEventHandler);
    }

    /**
     * On window resize event
     */
    onWindowResize() {
        this.setState({
            wrapperPosition: this.sectionWrapperPlaceholder.offsetPosition(),
        });

        const device = getDevice();

        if (this.state.device !== device) {
            this.setState({
                device,
                currentSlidePosition: 0
            });

            this.setSectionActiveSlide()
        }

        this.onCheckPosition();
    }

	/**
	 * On button up click
	 */
	onButtonScrollUpClick() {
		this.setState({ currentSlidePosition: 0 });
		this.setPreventScroll(false);
		scrollPage(0, 500);
		setTimeout(() => this.setSectionActiveSlide(), 600);
	}

    /**
     * On button down click
     */
    onButtonScrollDownClick() {
        this.setState({ currentSlidePosition: this.state.countSlides });
        this.setPreventScroll(false);
        scrollPage(this.state.wrapperPosition.top + this.sectionWrapper.clientHeight, 500);
        setTimeout(() => this.setSectionActiveSlide(), 600);
    }

    /**
     * Set section active slide
     */
    setSectionActiveSlide() {
        this.accordion.accordions.setActiveIndex(this.state.currentSlidePosition);
        this.images.removeClassFromAll('is-active');
        this.images[this.state.currentSlidePosition].classList.add('is-active');
    }

    /**
     * Prevent window scrolling
     */
    preventWindowScroll() {
        if (this.state.isPreventScroll){
            document.querySelector('html').scrollTop = this.state.wrapperPosition.top;
            window.scroll(0, this.state.wrapperPosition.top);
        }
    }

    /**
     * Get scroll direction
     * @returns {down | up}
     */
    getScrollDirection() {
        const scrollPosition = getScrollPosition();
        let scrollDirection;

        if (scrollPosition.top >= this.state.scrollPosition) {
            scrollDirection = 'down';
        } else {
            scrollDirection = 'up';
        }

        this.setState({ scrollPosition: scrollPosition.top });

        return scrollDirection;
    }

    /**
     * Set/unset page scroll and wheel
     * @param {Boolean} isOn
     */
    setPreventScroll(isOn = true) {
        if (isOn) {
            bodyOverflow(true, ENV.wheelSliderOverflowClassName);
            this.sectionWrapper.style.right = `${getScrollBarWidth()}px`;
            this.sectionWrapper.classList.add('is-fixed');
            this.sectionWrapperPlaceholder.style.height = `${this.sectionWrapper.clientHeight}px`;
            this.setState({
                isPreventScroll: true,
                isEnableMouseWheel: true,
            });
        } else {
            bodyOverflow(false, ENV.wheelSliderOverflowClassName);
            this.sectionWrapperPlaceholder.style.height = 0;
            this.sectionWrapper.classList.remove('is-fixed');
            this.sectionWrapper.style.right = 'auto';
            this.setState({
                isPreventScroll: false,
                isEnableMouseWheel: false,
            });
        }
    }

    /**
     * Check if scroll/wheel mechansm is enabled
     * @returns {Boolean}
     */
    isMechanismEnabled() {
        const isEnabled = this.state.device === 'desktop' && !isIE() && !isMobileDevice();

        if (isEnabled) {
            this.sectionWrapper.classList.add('is-enabled-wheel');
        } else {
            this.sectionWrapper.classList.remove('is-enabled-wheel');
        }

        return isEnabled;
    }

    /**
     * Get wheel supported event
     * @returns {wheel | mousewheel | DOMMouseScroll}
     */
    getWheelEvent() {
        if ('onwheel' in document) {
            return 'wheel';
        }

        if (document.onmousewheel !== undefined) {
            return 'mousewheel';
        }

        return 'DOMMouseScroll';
    }

    /**
     * Init component state
     */
    initState() {
        this.state = {
            device: getDevice(),
            scrollPosition: 0,
            isPreventScroll: false,
            isEnableMouseWheel: false,
            countSlides: this.images.length - 1,
            currentSlidePosition: 0,
            wrapperPosition: this.sectionWrapperPlaceholder.offsetPosition(),
        };
    }
}

export default WheelSlider;
