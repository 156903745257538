export const PeopleBoxes = () => { 

    function setPeopleBoxesHeight() {
        if( document.getElementsByClassName('js--people-image').length > 0 ){
            let itemWidth = document.querySelector('.js--people-image').getBoundingClientRect().width;            
            document.querySelectorAll('.js--people-image').forEach((item) => {        
                item.style.height = itemWidth+'px';
            });
        }
      }

      setPeopleBoxesHeight();      
      window.addEventListener('resize', () => {
        setPeopleBoxesHeight();
      });
      
};