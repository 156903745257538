import Swiper, { Navigation, Pagination } from 'swiper';
import ENV from '../Config/Env';

export const RelatedContentSlider = () => {
    if( document.getElementsByClassName('js--related-content-swiper').length > 0 ){
        let loopedSlides = document.querySelector('.js--related-content-swiper').dataset.slidesCount;
        
        let relatedContentSlider = new Swiper('.js--related-content-swiper', {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            initialSlide: 1,
            spaceBetween: 40,
            loop: true,
            navigation: {
                nextEl: '.js--related-content-slider-arrow-right',
                prevEl: '.js--related-content-slider-arrow-left',
            },
            pagination: {
                el: '.js--related-content-slider-pagination',
                type: 'bullets',
                clickable: true
            },
            breakpoints: {
                600: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                767: {
                    pagination: {
                        clickable: true,
                    },
                },
                1000: {
                    slidesPerView: 2,
                    initialSlide: 0,
                    spaceBetween: 30,
                }
            }
        });
        //console.log( relatedContentSlider );
        relatedContentSlider.on('resize', function(){            
            relatedContentSlider.update();
        });

    }
};
