import ENV from '../Config/Env';

const newWindowOpen = () => {
	const windowWidth = window.innerWidth;
	if (windowWidth > ENV.breakpoints['sm-max']) {
		const socialSharingBox = document.querySelector('.js-social-sharing');
		if (socialSharingBox !== null) {
			const newWindowAnchors = document.querySelectorAll('.js-new-window');
			const newWindowLeft = (windowWidth - 770) / 2;

			const newWindowTop = 300;
			for (let i = 0; i < newWindowAnchors.length; i++) {
				newWindowAnchors[i].addEventListener('click', function (event) {
					event.preventDefault();
					const newWindowUrl = this.getAttribute('href');
					window.open(newWindowUrl, '', 'width=770, height=380, top=' + newWindowTop + ', left=' + newWindowLeft + ' scrollbar=no, resize=yes, menus=no');
				});
			}
		}
	}
};

export default newWindowOpen;
