import {getScrollPosition} from "./Scroll";

const is_iOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export function getScrollBarWidth() {
	return window.innerWidth - document.body.offsetWidth;
}

export function setBodyFixed() {
	if (is_iOS()) {
		const position = getScrollPosition().top;
		document.body.classList.add('ios-body-fixed-overflow');
		document.body.style.top = `-${position}px`;
		document.body.setAttribute('data-scroll-position', position);
	}
}

export function unsetBodyFixed() {
	if (is_iOS() && document.body.classList.contains('ios-body-fixed-overflow') && document.body.hasAttribute('data-scroll-position')) {
		const position = parseInt(document.body.getAttribute('data-scroll-position'));
		document.body.removeAttribute('data-scroll-position');
		document.body.classList.remove('ios-body-fixed-overflow');
		document.body.style.top = `auto`;
		window.scroll(0, position);
	}
}

export function bodyOverflow(overflow = false, additionalClassName) {
	const btnMenu = document.querySelector('.js--h-button');
	let scrollBarWidth = getScrollBarWidth();

	window.addEventListener('resize', () => {
		scrollBarWidth = getScrollBarWidth();
	});

	if (overflow) {
		document.body.style.marginRight = `${scrollBarWidth}px`;
		document.body.classList.add('body-overflow');

		if (additionalClassName) {
			document.body.classList.add(additionalClassName);
		}

		if (!btnMenu.classList.contains('is-offset-with-scrollbar')) {
			btnMenu.classList.add('is-offset-with-scrollbar')
			const currentValueRight = parseInt(getComputedStyle(btnMenu.parentNode)['right'], 10);
			btnMenu.parentNode.style.right = currentValueRight + scrollBarWidth + 'px'; //update calculation and correct hamburger position with scrollbar
		}

	} else {
		if (additionalClassName) {
			document.body.classList.remove(additionalClassName);
		}
		document.body.classList.remove('body-overflow');
		document.body.style.marginRight = `0`;
		btnMenu.classList.remove('is-offset-with-scrollbar')
		btnMenu.parentNode.removeAttribute('style'); //update calculation and correct hamburger position
	}
}
