import ENV from '../Config/Env';

export const getDevice = () => {
    const width = window.innerWidth;

    if (width <= ENV.breakpoints['sm-max']){
        return 'mobile';
    } else if (width >= ENV.breakpoints['md'] && width <= ENV.breakpoints['md-max']) {
        return 'tablet';
    } else {
        return 'desktop';
    }
};

export const isIE = () => navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
