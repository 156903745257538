import Cookie from '../Helpers/Cookies';

const cookie = new Cookie();

class Announcement {
	constructor() {
		this.init();
	}

	init() {
		this.announcement = document.querySelector('.js--announcement-notice');
		this.announcementChild = document.querySelector('.js--announcement-notice > div');
		this.button = document.querySelector('.js--announcement-button');
		this.buttonClose = document.querySelector('.js--close-button');
		this.body = document.querySelector('body');

		if (!this.announcement) {
			return;
		}

		this.checkCookies();
		this.closeEvent();
		this.buttonEvent();
	}

	calcHeight() {
		this.announcement.style.maxHeight = `${this.announcementChild.offsetHeight}px`;

		window.addEventListener('resize', () => {
			this.announcement.style.maxHeight = null;
			this.announcement.style.maxHeight = `${this.announcementChild.offsetHeight}px`;
		});
	}

	buttonEvent() {
		if (!this.button) {
			return;
		}

		this.button.addEventListener('click', () => {
			this.announcement.style.display = 'none';
			this.body.classList.remove('active-announcement');
			this.saveCookie();
		});
	}

	closeEvent() {
		if (!this.buttonClose) {
			return;
		}

		this.buttonClose.addEventListener('click', () => {
			this.announcement.style.display = 'none';
			this.body.classList.remove('active-announcement');
			this.saveCookieCloseAnnouncement();
		});
	}

	saveCookie() {
		cookie.createCookie('announcement', 'accept', 1, __jsVars.cookie.path, __jsVars.cookie.domain, __jsVars.cookie.secure);
	}

	saveCookieCloseAnnouncement() {
		cookie.createCookie('announcement_close', 'accept', 1, __jsVars.cookie.path, __jsVars.cookie.domain, __jsVars.cookie.secure);
	}

	checkCookies() {
		if (!(cookie.readCookie('announcement') === 'accept')) {
			this.announcement.style.display = 'flex';
			this.calcHeight();
			this.body.classList.add('active-announcement');
		}
	}
}

export default Announcement;
