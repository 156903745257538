const MESSAGES = {
    errors: {
        default: 'An unexpected error has occurred, please try again later',
        required: 'This field is required',
        email: 'Please enter a valid email address',
        fillOut: 'Error: Please fill out all fields.',
        success: 'Message was sent. Thank you.',
    }
};

export default MESSAGES;
