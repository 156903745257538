export const HoveredBoxes = () => { 

    function setHoverBoxesHeight() {
      const windowWidth = window.innerWidth;
        if( document.getElementsByClassName('js--hovered-box-item').length > 0 ){
            let itemWidth = document.querySelector('.js--hovered-box-item').getBoundingClientRect().width;
            document.querySelectorAll('.js--hovered-box-item').forEach((item) => {
                item.style.height = itemWidth+'px';
            });
            if( windowWidth<=767 ){
              document.querySelectorAll('.js--show-hovered-box').forEach((item) => {
                item.addEventListener('click', () => {                
                  document.querySelectorAll('.js--hovered-box-item').forEach((box) => {
                    box.classList.remove('hovered-boxes__item-active');
                    document.querySelector('body').classList.remove('hide-overflow');
                  });
                  if( item.parentNode.classList.contains('hovered-boxes__item-active') ){
                    item.parentNode.classList.remove('hovered-boxes__item-active'); 
                    document.querySelector('body').classList.remove('hide-overflow');
                  }else{
                    item.parentNode.classList.add('hovered-boxes__item-active');
                    document.querySelector('body').classList.add('hide-overflow');
                  }
                });
              });
              document.querySelectorAll('.js--hide-hovered-box').forEach((item) => {
                item.addEventListener('click', () => {                
                  document.querySelectorAll('.js--hovered-box-item').forEach((box) => {                  
                    box.classList.remove('hovered-boxes__item-active');
                    document.querySelector('body').classList.remove('hide-overflow');
                  });
                });
              });
            }
        }
      }

      setHoverBoxesHeight();      
      window.addEventListener('resize', () => {
        setHoverBoxesHeight();
      });
      
};