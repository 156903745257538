const selectors = {
	bar: '.js--filter-bar',
	dropdown: '.js--filter-dropdown',
	formButtons: '.js--form__buttons',
	checkboxButton: '.js--filter-checkbox',
};

class FilterSelect {

	constructor() {
		this.bar = document.querySelector(selectors.bar);
		this.dropdown = document.querySelector(selectors.dropdown);

		if (!this.bar) {
			return;
		}

		this.toggleDropdown();
		this.hideDropdownOnClickOutside(this.dropdown);
	}

	toggleDropdown() {
		this.bar.addEventListener('click', (evt) => {
			evt.stopPropagation();
			this.bar.parentElement.classList.toggle('active');
		});
	}

	hideDropdownOnClickOutside(element) {
		document.addEventListener('click', (evt) => {
			if (!element.contains(evt.target)) {
				element.parentElement.classList.remove('active');
			}
		});
	}
}

class FilterButtons {
	constructor(){
		this.checkbox = document.querySelectorAll(selectors.checkboxButton);
		this.formButtons = document.querySelector(selectors.formButtons);

		if (!this.formButtons) {
			return;
		}
		this.autoSubmit();
	}

	autoSubmit(){
		const checkboxes = this.checkbox;

		for (let i = 0; i < checkboxes.length; i++) {
			checkboxes[i].addEventListener('change', () => {
				this.formButtons.submit();
			})
		}
	}

}

class SingleBlogAnchors {
	constructor(){
		let anchorsElems = document.querySelectorAll( ".single-post .site-main a" );
		if( anchorsElems.length > 0 ){
			for (let i = 0; i < anchorsElems.length; i++) {
				anchorsElems[i].setAttribute("target", "_blank");
			}
		}
	}
}

export {FilterSelect, FilterButtons, SingleBlogAnchors};
