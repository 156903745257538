import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';

import ENV from '../Config/Env';
import {isChromeForIOs} from '../Helpers/PointerInfo';

export const HoveredBoxesSlider = () => {    
    if( document.getElementsByClassName('js--hovered-boxes-swiper').length > 0 ){
        let loopedSlides = document.querySelector('.js--hovered-boxes-swiper').dataset.slidesCount;
        let hoveredBoxesSwiper;
        if( isChromeForIOs() ){
            //console.log('THIS is CHROME for iOS.');
            hoveredBoxesSwiper = new Swiper('.js--hovered-boxes-swiper', {     
                modules: [Navigation, Pagination, EffectFade],
                effect: 'slide',
                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,
                navigation: {
                    nextEl: '.js--slider-arrow-right',
                    prevEl: '.js--slider-arrow-left',
                },
                pagination: {
                    el: '.js--slider-pagination',
                    type: 'bullets',
                },
            });
        }else{
            //console.log('THIS is NOT CHROME for iOS.');            
            hoveredBoxesSwiper = new Swiper('.js--hovered-boxes-swiper', {
                modules: [Navigation, Pagination],
                effect: 'slide',
                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,
                navigation: {
                    nextEl: '.js--slider-arrow-right',
                    prevEl: '.js--slider-arrow-left',
                },
                pagination: {
                    el: '.js--slider-pagination',
                    type: 'bullets',
                    clickable: true,
                },
                breakpoints: {
                    750: {
                        slidesPerView: 1.5,
                        initialSlide: 1,
                        spaceBetween: 30,
                        pagination: false
                    },
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                        pagination: false
                    },
                }
            });
        }
        
        hoveredBoxesSwiper.on('slideChangeTransitionStart', function(){            
            hoveredBoxesSwiper.el.querySelectorAll('.js--hovered-box-slider-item').forEach((item) => {
                item.classList.remove('hovered-boxes-slider__item__active');
            });
        });

    }
    hoveredBoxClicks();
    window.addEventListener('resize', () => {
		hoveredBoxClicks();
	});
};

export function hoveredBoxClicks() {
	const windowWidth = window.innerWidth;
    if( document.getElementsByClassName('js--hovered-box-slider-item').length > 0 ){        
        document.querySelectorAll('.js--hovered-box-slider-item').forEach((item) => {
            if ( windowWidth <= ENV.breakpoints['md-max'])  {                
                item.addEventListener('click', () => {                
                    if( item.classList.contains('hovered-boxes-slider__item__active') ){
                        item.classList.remove('hovered-boxes-slider__item__active');
                    }else{
                        item.classList.add('hovered-boxes-slider__item__active');
                    }
                });
            }
        });
    }
}