class BaseClass {
    constructor () {
        this.dispatchHandlers = {};
        this.state = {};
    }

    /**
     * SET STATE
     * @param {Object} newState
     */
    setState(newState = {}) {
        this.state = {
            ...this.state,
            ...newState,
        };
    }

    /**
     * Event listener
     * @param {String} eventName
     * @param {Function} callback
     */
    on(eventName, callback) {
        const existsHandlerCollection = this.dispatchHandlers[eventName];

        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }

        return this;
    }

    /**
     * Event emiter
     * @param {String} eventName
     * @param {Object} data
     */
    emit(eventName, data = {}) {
        const handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default BaseClass;
