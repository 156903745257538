/** HELPERS */
import './Helpers/Polyfill';
import './Helpers/Prototypes';

/** VENDOR */
import Accordion from 'vendor/Accordion/Accordion';
import Expandable from 'vendor/Expandable/Expandable';

/** COMMON */
import Header from './Common/Header';
import Footer from './Common/Footer';
import ContactForm from './Common/ContactForm';
import GDPR from './Common/GDPR';
import Announcement from './Common/Announcement';
import LoadMore from './Common/LoadMore';
import {FilterSelect, FilterButtons, SingleBlogAnchors} from './Common/Filter';
import LocationScroll from "./Common/LocationScroll";
import newWindowOpen from "./Common/SocialMedia";
import Parallax from "./Common/Parallax";
import ViewTheFilmPopup from "./Common/ViewTheFilmPopup";

/** SECTIONS */
import FullScreenSlider from './Sections/FullScreenSlider';
import WheelSlider from "./Sections/WheelSlider";
import HoverScrollSlider from "./Sections/HoverScrollSlider";
import StickyNav from "./Sections/StickyNav";
import SidebarMenu from './Sections/SidebarMenu';
import MeetShowHide from './Sections/MeetUs';
import MapManipulation from './Sections/Map';
import { Gallery } from './Sections/Gallery';
import { HoveredBoxes } from './Sections/HoveredBoxes';
import { HoveredBoxesSlider } from './Sections/HoveredBoxesSlider';
import { TilesGrid } from './Sections/TilesGrid';
import { FullScreenSliderWithText } from './Sections/FullScreenSliderWithText';
import { Circles } from './Sections/Circles';
import { PeopleBoxes } from './Sections/People';
import { RelatedContentSlider } from './Sections/RelatedContentSlider';
import InterviewsScrollSlider from "./Sections/InterviewsScrollSlider";

/** STYLES */
import '../scss/Index.scss';

class IndexController {
    constructor (){
        LocationScroll();
        Gallery();
		newWindowOpen();
		LoadMore();

        new Accordion('.js--section-accordion');
        new Expandable();
        new FullScreenSlider();
        new WheelSlider();
        new HoverScrollSlider();
        new StickyNav();
        new SidebarMenu();
        new Header();
        new Footer();
        new FilterSelect();
        new FilterButtons();
        new SingleBlogAnchors();
        new MeetShowHide();
        new ContactForm();
        new GDPR();
        new Announcement();
		new Parallax();
		new MapManipulation();
        HoveredBoxes();
        HoveredBoxesSlider();
        TilesGrid();
        FullScreenSliderWithText();
        Circles();
        PeopleBoxes();
        RelatedContentSlider();
        new InterviewsScrollSlider();
        RelatedContentSlider();
        ViewTheFilmPopup();
    }
}

// Loader babel polyfill only if needed
const BROWSER_UNSUPPORT_FEATURES = !window.Promise || !window.fetch || !window.Symbol || !Object.assign;

if (BROWSER_UNSUPPORT_FEATURES && typeof __jsVars !== 'undefined' && typeof __jsVars.dist !== 'undefined' && typeof __jsVars.dist.babelPolyfill !== 'undefined') {    
    const js = document.createElement('script');
    js.src = __jsVars.dist.babelPolyfill;
    js.onload = function() {
        new IndexController();
    };
    js.onerror = function() {
        new IndexController();
        new Error('Failed to load script ' + __jsVars.dist.babelPolyfill);
    };
    document.head.appendChild(js);
} else {
	document.addEventListener('DOMContentLoaded', () => {        
		new IndexController();
	});
}
