export const isIosSystemThirteenVersion = () => navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream;

export const isMobileDevice = () => /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(navigator.userAgent) || isIosSystemThirteenVersion();

const userAgent = window.navigator.userAgent;
export const isChromeForIOs = () => /CriOS/i.test(userAgent);

export const isUsingMousePointer = () => 'PointerEvent' in window || window.matchMedia('(pointer:fine)').matches;

export const isUsingTouchPointer = () => {
    const isMobileTouch = isMobileDevice() && navigator.maxTouchPoints > 0;
 
    return isMobileTouch
        || isIeTouch
        || window.matchMedia('(pointer:coarse)').matches
        || window.matchMedia('(pointer:none)').matches
        || window.matchMedia('(any-pointer:coarse)').matches
        || window.matchMedia('(any-pointer:none)').matches
 }