import './Expandable.scss';

const EXPANDABLE_PROPS = {
    bodySelector: '.js--expandable-body',
    contentSelector: '.js--expandable-content',
    expandableIdAttr: 'data-expandable-id',
    defaultOpenedFromAttr: 'data-default-opened-from',
    expandedClassName: 'is-expanded',
};

class Expandable {
    constructor (buttonSelector = '.js--expandable-button', props = {}){
        this.buttons = document.querySelectorAll(buttonSelector);
        this.props = {
            ...EXPANDABLE_PROPS,
            ...props,
            buttonSelector: buttonSelector,
        };

        if (this.buttons.length > 0){
            this.showDefault();
            this.events();
        }
    }

    showDefault() {
        this.items = document.querySelectorAll(this.props.bodySelector);

        for (const item of this.items) {
            if (item.hasAttribute(this.props.defaultOpenedFromAttr) && item.hasAttribute(this.props.expandableIdAttr)) {
                const defaultOpenedFrom = parseInt(item.getAttribute(this.props.defaultOpenedFromAttr));
                const expandableId = item.getAttribute(this.props.expandableIdAttr);
                
                if (window.innerWidth >= defaultOpenedFrom) {
                    const expandableButtons = document.querySelectorAll(`${this.props.buttonSelector}[${this.props.expandableIdAttr}="${expandableId}"]`);
                    const expandableBody = document.querySelector(`${this.props.bodySelector}[${this.props.expandableIdAttr}="${expandableId}"]`);

                    if (expandableButtons && expandableBody) {
                        this.show(expandableButtons, expandableBody);
                    }
                }
            }
        }
    }

    /**
     * Component actions
     */
    events () {
        this.buttons.onEvent('click', (event) => {
            event.preventDefault();

            if (event.target.hasAttribute(this.props.expandableIdAttr)) {
                const expandableId = event.target.getAttribute(this.props.expandableIdAttr);
                const expandableButtons = document.querySelectorAll(`${this.props.buttonSelector}[${this.props.expandableIdAttr}="${expandableId}"]`);
                const expandableBody = document.querySelector(`${this.props.bodySelector}[${this.props.expandableIdAttr}="${expandableId}"]`);

                if (expandableButtons && expandableBody) {
                    this.toggle(expandableButtons, expandableBody);
                }
            }
        });

        window.addEventListener('resize', this.resize.bind(this));
    }

    /**
     * Toggle expandable body
     * @param {NodeList} buttons 
     * @param {HTMLElement} body 
     */
    toggle(buttons, body) {
        if (body.classList.contains(this.props.expandedClassName)) {
            this.hide(buttons, body);
        } else {
            this.show(buttons, body);
        }
    }

    /**
     * Hide expandable body
     * @param {NodeList} buttons 
     * @param {HTMLElement} body 
     */
    hide (buttons, body) {
        buttons.removeClassFromAll(this.props.expandedClassName);
        body.classList.remove(this.props.expandedClassName);
        body.style.height = null;
    }

    /**
     * Show expandable body
     * @param {NodeList} buttons 
     * @param {HTMLElement} body
     */
    show (buttons, body) {
        buttons.addClassToAll(this.props.expandedClassName);
        body.classList.add(this.props.expandedClassName);
        body.style.height = `${body.querySelector(this.props.contentSelector).scrollHeight}px`;
    }

    /**
     * On window resize
     */
    resize() {
        const expandedBodies = document.querySelectorAll(`${this.props.bodySelector}.${this.props.expandedClassName}`);

        if (expandedBodies.length > 0){
            for (const body of expandedBodies) {
                body.style.height = `${body.querySelector(this.props.contentSelector).scrollHeight}px`;
            }
        }

        this.showDefault();
    }
}

export default Expandable;
