if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

/**
 * Prototype for add event to Element
 * @param {String} eventName 
 * @param {function} callback 
 */
Element.prototype.onEvent = function (eventName, callback) {
    if (this) {
        this.addEventListener(eventName, callback);
    }

    return this;
}

/**
 * Prototype for add events to NodeList
 * @param {String} eventName 
 * @param {function} callback 
 */
NodeList.prototype.onEvent = function (eventName, callback) {
    if (this instanceof NodeList) {
        for (let i = 0; i < this.length; i++) {
            this[i].addEventListener(eventName, callback);
        }
    }

    return this;
}

/**
 * Prototype for add class to NodeList
 * @param {String} className 
 */
NodeList.prototype.addClassToAll = function (className) {
    if (this instanceof NodeList) {
        for (let i = 0; i < this.length; i++) {
            this[i].classList.add(className);
        }
    }
    
    return this;
}

/**
 * Prototype for remove class from NodeList
 * @param {String} className 
 */
NodeList.prototype.removeClassFromAll = function (className) {
    if (this instanceof NodeList) {
        for (let i = 0; i < this.length; i++) {
            this[i].classList.remove(className);
        }
    }
    
    return this;
}

/**
 * Prototype for getting element offset position
 */
Element.prototype.offsetPosition = function() {
    const rect = this.getBoundingClientRect(),
        scrollTop = window.pageYOffset || document.documentElement.scrollTop,
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft
    }
};

