import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';

export const Gallery = () => {
    new Swiper('.js--gallery-swiper', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        cssMode: true,
        loop: true,
        pagination: {
            el: '.js--gallery-pagination',
            clickable: true,
        },
    })
};