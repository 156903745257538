import Swiper, { Navigation, Pagination, FreeMode } from 'swiper';
import BaseClass from 'vendor/BaseClass';
import ENV from '../Config/Env';
import {getDevice} from '../Helpers/Device';
import {isMobileDevice} from '../Helpers/PointerInfo';

const HOVER_SCROLL_SLIDER_SELECTORS = {
	arrowLeft: '.js--slider-arrow-left',
	arrowRight: '.js--slider-arrow-right',
	sliderImg: '.js--slider-img',
};

const HOVER_SCROLL_SLIDER_PROPS = {
	wrapperSelector: '.js--hover-scroll-slider',
	swiperSelector: '.js--hover-scroll-swiper',
};

class HoverScrollSliderItem extends BaseClass {
	constructor(section) {
		super();
		this.section = section;
		this.swiperElement = this.section.querySelector(HOVER_SCROLL_SLIDER_PROPS.swiperSelector);
		this.init();
	}

	/**
	 * Component init
	 */
	init() {
		this.swiper = null;
		this.timer = null;

		this.initState();
		this.events();
		this.initSwiper();
		this.setArrowCenterVertical();
	}

	/**
	 * Component actions
	 */
	events() {
		window.addEventListener('resize', () => this.onWindowResize());
		this.section.onEvent('mousemove', (event) => this.startScrolling(event)).onEvent('mouseleave', () => this.stopScrolling());
	}

	/**
	 * On window resize
	 */
	onWindowResize() {
		// Set device on window resize
		this.setState({isMobileDevice : isMobileDevice()});

		// Stop scrolling on resize when device is mobile
		if (this.state.isMobileDevice === true) {
			this.stopScrolling();
		}

		// Run reinit carousel slider
		this.reinitSwiper();
	}

	/**
	 * Start carousel scrolling
	 * @param {MouseEvent} event
	 */
	startScrolling(event) {
		// Get slider action based on mouse position (`left`, `right`, `stop`)
		const currentAction = this.getSliderActionArrows(event);

		// Prevent method when current action is running or device is not `desktop`
		if (currentAction === this.state.currentAction || isMobileDevice() === true) {
			return;
		}

		// Update component state with new action
		this.setState({currentAction});

		// Run/stop scrolling based on current action
		switch (currentAction) {
			case 'right':
				clearInterval(this.timer);
				this.timer = setInterval(() => {
					if (Math.abs(this.swiper.getTranslate()) < this.swiper.snapGrid[this.swiper.snapGrid.length - 1]) {
						this.swiper.setTranslate(this.swiper.getTranslate() - 1);
					} else {
						clearInterval(this.timer);
					}
				}, 5);
				break;
			case 'left':
				clearInterval(this.timer);
				this.timer = setInterval(() => {
					if (this.swiper.getTranslate() < this.swiper.snapGrid[0]) {
						this.swiper.setTranslate(this.swiper.getTranslate() + 1);
					} else {
						clearInterval(this.timer);
					}
				}, 5);
				break;
			default:
				this.stopScrolling();
		}
	}

	/**
	 * Stop carousel scrolling
	 */
	stopScrolling() {
		clearInterval(this.timer);
		this.timer = null;
		this.setState({currentAction: 'stop'});
	}


	/**
	 * Get mouse position
	 * @param {MouseEvent} event
	 */
	getSliderActionArrows(event) {
		const rect = this.section.getBoundingClientRect();
		const arrowLeftRect = document.querySelector(HOVER_SCROLL_SLIDER_SELECTORS.arrowLeft).getBoundingClientRect();
		const arrowRightRect = document.querySelector(HOVER_SCROLL_SLIDER_SELECTORS.arrowRight).getBoundingClientRect();

		const arrowLeftBox = {
			top: arrowLeftRect.top - rect.top,
			bottom: arrowLeftRect.top - rect.top + arrowLeftRect.height,
			left: arrowLeftRect.left + arrowLeftRect.width
		};

		const arrowRightBox = {
			top: arrowRightRect.top - rect.top,
			bottom: arrowRightRect.top - rect.top + arrowRightRect.height,
			left: arrowRightRect.left
		};

		/* cursor position */
		const cursorPositionX = event.clientX - rect.left;
		const cursorPositionY = event.clientY - rect.top;

		if (cursorPositionX < arrowLeftBox.left && cursorPositionY > arrowLeftBox.top && cursorPositionY < arrowLeftBox.bottom) {
			return 'left';
		} else if (cursorPositionX > arrowRightBox.left && cursorPositionY > arrowRightBox.top && cursorPositionY < arrowRightBox.bottom) {
			return 'right';
		} else {
			return 'stop';
		}


	}

	/**
	 * Init carousel slider
	 */
	initSwiper() {
		if (this.swiperElement && isMobileDevice() === false) {
			this.swiper = new Swiper(this.swiperElement, {
				modules: [Navigation, Pagination, FreeMode],
				slidesPerView: 'auto',
				freeMode: true,
				loopAdditionalSlides: 2,
				allowTouchMove: false,
				loop: true,
				breakpoints: {
					[ENV.breakpoints['sm-max']]: {
						freeMode: false,
						slidesPerView: 'auto',
						allowTouchMove: true,
						pagination: {
							el: '.swiper-pagination',
							clickable: true,
						},
					},
					[ENV.breakpoints['xs-max']]: {
						freeMode: false,
						slidesPerView: 'auto',
						allowTouchMove: true,
						pagination: {
							el: '.swiper-pagination',
							clickable: true,
						},
					},

				}
			});
		} else if(this.swiperElement && isMobileDevice() === true) {
			this.swiper = new Swiper(this.swiperElement, {
				modules: [Navigation, Pagination, FreeMode],
				slidesPerView: 'auto',
				loopAdditionalSlides: 2,
				slidesPerView: 2,
				initialSlide: 1,
				allowTouchMove: true,
				loop: true,
				navigation: {
					nextEl: '.js--slider-arrow-right',
					prevEl: '.js--slider-arrow-left',
				},
				breakpoints: {
					[ENV.breakpoints['sm-max']]: {
						slidesPerView: 2,
                        initialSlide: 1,
						pagination: {
							el: '.swiper-pagination',
							clickable: true,
						},
					},
					[ENV.breakpoints['xs-max']]: {
						slidesPerView: 1,
                        initialSlide: 1,
						pagination: {
							el: '.swiper-pagination',
							clickable: true,
						},
					},
				}
			});
		}
	}

	/**
	 * Reinit carousel slider between change devices
	 */
	reinitSwiper() {
		// Reinit only when current device is not same with prev device
		if (isMobileDevice() !== this.state.checkMobile) {
			if (this.swiper) {
				this.swiper.destroy(true, true);
				this.swiper = null;
				this.initSwiper();
			}
			this.setState({
				prevDevice: this.state.device,
				checkMobile: isMobileDevice(),
			})
		}
	}

	/**
	 * Init component state
	 */
	initState() {
		const currentDevice = getDevice();

		this.state = {
			currentAction: 'stop',
			device: currentDevice,
			prevDevice: currentDevice,
		};
	}

	/**
	 * center the arrows vertically
	 */
	setArrowCenterVertical() {
		const arrowLeft = document.querySelector(HOVER_SCROLL_SLIDER_SELECTORS.arrowLeft);
		const arrowRight = document.querySelector(HOVER_SCROLL_SLIDER_SELECTORS.arrowRight);

		function setArrowVertical() {
			const heightSlideImage = document.querySelector(HOVER_SCROLL_SLIDER_SELECTORS.sliderImg).getBoundingClientRect().height;
			arrowLeft.style.top = `${heightSlideImage / 2}px`;
			arrowRight.style.top = `${heightSlideImage / 2}px`;
		}

		setArrowVertical();

		window.addEventListener('resize', () => {
			setArrowVertical();
		});
	}
}

class HoverScrollSlider {
	constructor() {
		const sections = document.querySelectorAll(HOVER_SCROLL_SLIDER_PROPS.wrapperSelector);

		if (sections.length > 0) {
			for (const section of sections) {
				new HoverScrollSliderItem(section);
			}
		}
	}
}

export default HoverScrollSlider;
