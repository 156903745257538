export const Circles = () => { 
    const selectors = {
        circles: '.js--circles',        
    };
    function setCirclesHeight() {
        const windowWidth = window.innerWidth;        
        if( document.getElementsByClassName('js--circles').length > 0 ){
            if( windowWidth<=767 ){
                let itemWidth = document.querySelector('.js--circle-item').getBoundingClientRect().width+20;
                document.querySelectorAll('.js--circle-item').forEach((item) => {
                    item.style.minHeight = itemWidth+'px';
                });
            }
        }
    }    
    if( document.getElementsByClassName('js--circles').length > 0 ){

        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
        let currentTopScroll = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
        let scrollAnimationActive = true;
        
        let firstSection = document.querySelector('.full-screen-slider-with-text');
        let circlesSection = document.querySelector('.js--circles');
        let leftCircle = document.querySelector('.circles__left-circle .circles__circle-wrapper');
        let rightCircle = document.querySelector('.circles__right-circle .circles__circle-wrapper');
        let circlesOutroHeight = 0;
        let circlesOutro = '';
        if( document.getElementsByClassName('circles__outro-wrapper').length > 0 ){
            circlesOutro = document.querySelector('.circles__outro-wrapper');
            circlesOutroHeight = circlesOutro.offsetHeight;        
        }
        const circles = document.querySelector('.circles__items');
        const circlesRect = circles.getBoundingClientRect();
        const firstSectionRect = firstSection.getBoundingClientRect();
        const circlesSectionRect = circlesSection.getBoundingClientRect();
        let circlesSectionHeight = circlesSection.offsetHeight;
        let circlesTop = circlesRect.top+currentTopScroll;
        let circlesTopMinus = circlesTop-circlesOutroHeight;
        let circlesTopPlus = circlesTop+circlesSectionHeight;        
        let circlesBottom = circlesRect.bottom+currentTopScroll;
        let firstSectionBottom = firstSectionRect.bottom+currentTopScroll;
        let firstSectionBottomPlus = firstSectionBottom+circlesOutroHeight;
        let circlesSectionBottom = circlesSectionRect.bottom+currentTopScroll;
        
        let lastScrollTopPos = 0;
        let startCirclePosition = 100;
        let endCirclePosition = -50;
        let circlesDistance = 150;
        let circlesHalfBottom = circlesBottom;
        let circlesHalfBottomPlus = circlesBottom;
        if( windowWidth>=768 && windowWidth<991 ){
            startCirclePosition = 50;
            endCirclePosition = -30;
            circlesDistance = 80;
            circlesHalfBottom = circlesBottom;
            circlesHalfBottomPlus = circlesBottom;
        }

        let currentScroll = (currentTopScroll / circlesTopMinus);
        let currentScrollPlus = (currentTopScroll / circlesTopPlus);
        let circlesCurrPos = -( circlesDistance * currentScroll ) + startCirclePosition;
        let currentOpacity = currentScrollPlus;
        if( windowWidth>=768 ){
            if( circlesCurrPos>endCirclePosition && circlesCurrPos<=startCirclePosition ){
                leftCircle.style.right = circlesCurrPos +'px';
                rightCircle.style.left = circlesCurrPos +'px';
                circlesOutro.style.opacity = currentOpacity;
            }else{
                leftCircle.style.right = endCirclePosition+'px';
                rightCircle.style.left = endCirclePosition+'px';
                circlesOutro.style.opacity = 1;
                
            }
            circlesOutro.style.opacity = currentScroll;
        }
    
        window.addEventListener('scroll', ()=> {
            if( windowWidth>=768 ){
                const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;            
                let scrollTopWindowEnd = scrollTop-300;
                if( scrollTop > firstSectionBottomPlus && scrollTop > lastScrollTopPos && scrollAnimationActive ){
                    let currentScrollMinus = (scrollTop) / circlesTopMinus;
                    let currentScroll = (scrollTop) / (circlesTop);
                    let currentScrollPlus = (scrollTop) / circlesTopPlus;
                    let circlesCurrPos = -( circlesDistance * currentScrollMinus ) + startCirclePosition;
                    let currentOpacity = currentScrollPlus;
                    if( circlesCurrPos>endCirclePosition && circlesCurrPos<=startCirclePosition ){
                        leftCircle.style.right = circlesCurrPos +'px';
                        rightCircle.style.left = circlesCurrPos +'px';
                    }else{
                        leftCircle.style.right = endCirclePosition+'px';
                        rightCircle.style.left = endCirclePosition+'px';
                    }
                    if( currentOpacity<1 ){
                        circlesOutro.style.opacity = currentOpacity;
                    }else{
                        circlesOutro.style.opacity = 1;
                    }
                }
                if( scrollTop > circlesSectionBottom ){
                    scrollAnimationActive = false;
                }
                lastScrollTopPos = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
            }            

            /*if (scrollTop > lastScrollTopPos){
                // scroll is going down
                if( scrollTopWindowEnd > circlesTop ) {
                    startCirclePosition = startCirclePosition-circlesDelta;
                    circlesOutro.style.opacity = opacityDelta;
                }
                startDelta++;
                console.log( 'scrollTop: '+scrollTop );
            } else {
                // scroll is going up
            }*/

        });
    }

    setCirclesHeight();      
    window.addEventListener('resize', () => {
        setCirclesHeight();
    });
      
};