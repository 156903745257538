const ENV = {
    requestTimeout: 10000,
    breakpoints: {
        'xs': 0,
        'xs-max': 575,
        'sm': 576,
        'sm-max': 767,
        'md': 768,
        'md-max': 991,
        'lg': 992,
        'lg-max': 1199,
        'xl': 1200
    },
    regex: {
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
    wheelSliderOverflowClassName: 'is-wheel-slider-overflow',
};

export default ENV;