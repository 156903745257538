import {bodyOverflow, getScrollBarWidth} from "../Helpers/Body";
import ENV from "../Config/Env";

const selectors = {
	header: '.js--header',
	hamburger: '.js--h-button',
	hamburgerWrapper: '.js--h-button-wrapper',
	messages: '.js--h-messages',
	menuItemHasChildren: '.js--main-menu > .menu-item-has-children > a:not([class])',
	fullMenu: '.js--full-menu',
	body: 'body',
	menuAnchors: 'a',
};

class Header {

	constructor() {
		this.header = document.querySelector(selectors.header);
		this.hamburger = document.querySelector(selectors.hamburger);
		this.hamburgerWrapper = document.querySelector(selectors.hamburgerWrapper);
		this.messages = document.querySelector(selectors.messages);
		this.fullMenu = document.querySelector(selectors.fullMenu);
		this.body = document.querySelector(selectors.body);
		this.scrollBarWidth = getScrollBarWidth();
		if (!this.header) {
			return;
		}

		this.toggleFullScreenMenu();
		this.toggleSubmenu();
		this.stickyButton();
	}


	toggleFullScreenMenu() {
		let flagForMenu = false;
		this.header.classList.add('active-hamburger');
		this.hamburger.addEventListener('click', () => {

			this.header.classList.toggle('active');
			this.fullMenu.classList.toggle('active');
			this.body.classList.toggle('active-menu');

			if (flagForMenu === true) {
				flagForMenu = false;

				if (!document.body.classList.contains(ENV.wheelSliderOverflowClassName)) {
					bodyOverflow(false);
				}
			} else {
				bodyOverflow(true);
				flagForMenu = true;
			}
		});
		
		this.menuAnchorsElems = this.fullMenu.querySelectorAll(selectors.menuAnchors);		
		const menuAnchorsElements = this.menuAnchorsElems;
		
		for (let i = 0; i < menuAnchorsElements.length; i++) {			
			menuAnchorsElements[i].addEventListener('click', (evt) => {
				//console.log( menuAnchorsElements[i].parentNode.classList );
				if( !menuAnchorsElements[i].parentNode.classList.contains('menu-item-has-children') ){
					this.header.classList.toggle('active');
					this.fullMenu.classList.toggle('active');
					this.body.classList.toggle('active-menu');
	
					if (flagForMenu === true) {
						flagForMenu = false;
	
						if (!document.body.classList.contains(ENV.wheelSliderOverflowClassName)) {
							bodyOverflow(false);
						}
					} else {
						bodyOverflow(true);
						flagForMenu = true;
					}
				}
			});
		};
	}


	toggleSubmenu() {

		this.menuItemHasChildren = document.querySelectorAll(selectors.menuItemHasChildren);
		const menuChildren = this.menuItemHasChildren;
		for (let i = 0; i < menuChildren.length; i++) {
			menuChildren[i].addEventListener('click', (evt) => {				
				evt.preventDefault();

				/* toggle .show class */
				menuChildren[i].parentNode.classList.toggle('show');

				/* siblings items */
				Array.prototype.filter.call(menuChildren[i].parentNode.parentNode.children, function (child) {
					if ((child !== menuChildren[i].parentNode) === true) {
						child.classList.remove('show');

						/* close siblings nested items */
						child.querySelectorAll('.show').forEach((item) => {
							item.classList.remove('show');
						});

					}
				});

				/* remove .sibling class if exist */
				menuChildren[i].parentNode.classList.remove('sibling');

				/* close nested items */
				menuChildren[i].parentNode.querySelectorAll('.show, .sibling').forEach((item) => {
					item.classList.remove('show', 'sibling');
				});
			});
		}

	}

	stickyButton(){

		const hamburgerWrapper = this.hamburgerWrapper;
		const messages = this.messages;
		const header = this.header;
		let lastScrollTop = 0;

		window.addEventListener('scroll', ()=> {
			const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
			/*if (scrollTop > messages.offsetHeight ) {
				hamburgerWrapper.classList.add('fixed');
				this.body.classList.add('sticky');
			} else {
				hamburgerWrapper.classList.remove('fixed');
				this.body.classList.remove('sticky');
			}*/
			if (scrollTop > header.offsetHeight ) {
				if (scrollTop > lastScrollTop){
					this.header.classList.remove('fixed');
					this.body.classList.remove('sticky');
				} else {
					this.header.classList.add('fixed');
					this.body.classList.add('sticky');
				}
			}else{
				this.header.classList.remove('fixed');
				this.body.classList.remove('sticky');
			}
			 lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
		});

	}
}

export default Header;
