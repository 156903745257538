import {scrollPage} from "../Helpers/Scroll";

const LocationScroll = () => {
	let hash = window.location.hash;

	// Check if clicked link include hash
	const onLinkClick = (element) => {
		const targetHref = element.href;
		const hash = targetHref.substr(targetHref.indexOf("#"));

		if (targetHref.indexOf("#") >= 0 && document.getElementById(hash.split("#")[1])) {
			event.preventDefault();
			scrollToElem(hash);
		}
	};

	// Find section by hash and scroll to this section
	const scrollToElem = (hash, changeHash = true) => {
		const hashId = hash.split("#")[1];
		const section = document.getElementById(hashId);

		if (hash.indexOf('section') === 1) {
			const sectionList = document.querySelectorAll('.page-content > section');
			const sectionIndex = parseInt(hashId.split('-')[1], 10) - 1;
			scrollPage(sectionList[sectionIndex], 400, 'linear', 0, () => {
				if (changeHash && window.location.hash !== hash) {
					history.pushState({}, '', hash);
				}
			})
		}

		if (section) {
			scrollPage(section, 400, 'linear', 0, () => {
				if (changeHash && window.location.hash !== hash) {
					history.pushState({}, '', hash);
				}
			})
		}
	};

	// Check hash in href on click any link
	document.body.addEventListener('click', (event) => {
		let element = event.target;
		let finded = false;

		while (element !== null && !finded) {
			if (element.matches('a')) {
				finded = true;
				onLinkClick(element);
			}
			element = element.parentElement;
		}
	}, false);

	// Scroll on hash change
	window.addEventListener("hashchange", () => {
		if (window.location.hash !== '') {
			scrollToElem(window.location.hash, false);
		} else {
			scrollPage(0, 400, 'linear');
		}
	}, false);

	// Scroll to section if hash is defined
	window.addEventListener("load", () => {
		if (hash) {
			scrollToElem(hash);
		}
	});
}

export default LocationScroll;
