import Cookie from '../Helpers/Cookies';

const cookie = new Cookie();

class GDPR {
	constructor(){
		this.init();
	}

	init() {
		this.notice = document.querySelector('.js--cookies-notice');
		this.close = document.querySelector('.js--cookies-button');
		this.body = document.querySelector('body');


		this.close.addEventListener('click', () => {
			this.notice.style.display = 'none';
			this.body.classList.remove('active-gdpr');
			this.saveCookie();
		});
		this.checkCookies();
	}

	calcHeight() {
		this.notice.style.maxHeight = `${this.notice.offsetHeight}px`;
	}

	saveCookie() {
		cookie.createCookie('cookies', 'accept', 365, __jsVars.cookie.path, __jsVars.cookie.domain, __jsVars.cookie.secure);
	}

	checkCookies() {
		if (!(cookie.readCookie('cookies') === 'accept')) {
			this.notice.style.display = 'flex';
			this.calcHeight();
			this.body.classList.add('active-gdpr');
		}
	}
}

export default GDPR;
